<script lang="ts">
    import {IconStarFilled} from "@tabler/icons-svelte";

    export let reviewScore: number = 0.0;
    export let size = 'large'
    let fontSize = 'text-5xl';
    let iconSize = 24;

    $: {
        switch(size) {
            case 'large':
                fontSize = 'text-5xl';
                iconSize = 24;
                break;
            case 'medium':
                fontSize = 'text-3xl';
                iconSize = 16;
                break;
            default:
                fontSize = 'text-xl';
                iconSize = 12;
                break;
        }
    }
</script>

<span class='{fontSize} flex flex-row items-center'>
    {#if reviewScore >= 4.0}
        {reviewScore?.toFixed(1)}
        <IconStarFilled size={iconSize} class='inline-block text-green-300' color='text-green-300'/>
    {:else if reviewScore >= 2}
        {reviewScore?.toFixed(1)}
        <IconStarFilled size={iconSize} class='inline-block text-orange-300' color='text-orange-300'/>
    {:else if reviewScore === 0}
        0.0
        <IconStarFilled size={iconSize} class='inline-block text-gray-300' color='text-gray-300'/>
    {:else}
        {reviewScore?.toFixed(1)}
        <IconStarFilled size={iconSize} class='inline-block text-red-300'/>
    {/if}
</span>